.navbar-toggler:focus {
  box-shadow: none;
}

.content {
  position: relative;
}

.screen_sm {
  display: block;
  width: 100%;
  height: 450px;
  overflow: scroll;
  border-radius: 1px;
  margin: 0 auto;
}

.screen_sm img {
  /* bottom: -110px; */
  width: 100%;
  height: auto;
}



/* .screen_sm:hover img {
  bottom: 0;
  -webkit-transition: all 11s;
  -moz-transition: all 11s;
  -ms-transition: all 11s;
  -o-transition: all 11s;
  transition: all 11s;
} */



/* .screen_sm2:hover img {
  bottom: 0;
  -webkit-transition: all 11s;
  -moz-transition: all 11s;
  -ms-transition: all 11s;
  -o-transition: all 11s;
  transition: all 11s;
} */

@media (max-width: 991.98px) {
  .home_img {
    width: 100%;
  }
}

.form-bg {
  border: none;
  padding: 19px 64px 18px;
  background: var(--accent);
  color: var(--white-color);
  font-size: 20px;
  line-height: 22px;
  border-radius: 36px;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: ease-out;
  border: 1px solid transparent;
}


.button-sm {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 180px;
}

.button-sm:before {
  content: "";
  background: linear-gradient(45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-sm 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-sm {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.button-sm a {
  color: white;
}

.button-sm:hover a {
  color: white;
}

.button-sm:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;

}