.scroll_view {
  width: 100%;
  margin: auto;
  cursor: pointer;

  img {
    width: 100%;
    object-fit: cover;
    object-position: top;
    height: 370px;
    transition: 8s all ease;

    &:hover {
      object-position: bottom;
    }
  }
}
